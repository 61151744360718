import configuredAxios from 'helpers/configuredAxios'
import { getDeviceUuid } from "helpers/guest";
import { getCookie } from 'helpers/cookies'
import { isMobileDevice } from 'helpers/utilities'

export const getStartedRecord = async (payload) => {
  const axios = configuredAxios('jobseeker')
  const device_udid = await getDeviceUuid();
  const userInfo = getCookie('user')

  const params = Object.assign({}, { device_id: device_udid }, {
    email: userInfo?.email,
    phone_num: userInfo?.phone_num
  }, payload);

  if (!params.email?.trim?.()) delete params.email
  if (!params.phone_num?.trim?.()) delete params.phone_num

  return axios.post('/click/get-started-record', params)
}

export const hotSearch = async ({ page, source, query, job_title_id }) => {
  try {
    const isMobile = isMobileDevice()
    const axios = await configuredAxios('job')
    const device_udid = await getDeviceUuid()
    const data = await axios.post(`/metrics/job-hot-search`, {
      device: isMobile ? 'mobile_web' : 'web',
      page,
      source,
      query,
      job_title_id,
    })
  } catch (err) {
    throw new Error(err)
  }
}